<template>
    <a-modal :title="handle === 'add' ? '新增' : '编辑'" style="top: 8px;" :width="1000" v-model="visible" @ok="toSubmit">
        <a-form-model ref="form" :model="rowData" :rules="formRule" layout="horizontal" :label-col="{span:3}" >
            <a-form-model-item label="大区事务所" :autoLink="false" ref="text">
                <a-cascader
                v-model="dealerId"
                placeholder="请选择"
                :options="dealerList"
                @change="onChange"
                :field-names="{ label: 'title', value: 'id', children: 'children' }">
                </a-cascader>
            </a-form-model-item>
            <a-form-model-item label="经销商" prop="dealerName">
                <SelectDealerModal :value.sync="rowData.dealerName" :officeId="rowData.firmId"  @select="toSelectDealer"></SelectDealerModal>
            </a-form-model-item>
            <a-form-model-item label="折扣名称" prop="rebateName">
                <a-input v-model="rowData.rebateName" placeholder="折扣名称"></a-input>
            </a-form-model-item>
            <a-form-model-item label="折扣金额" prop="rebateAmount">
                <a-input v-model="rowData.rebateAmount" placeholder="折扣金额"></a-input>
            </a-form-model-item>
            <a-form-model-item label="适用范围" prop="categoryIds" :autoLink="false" ref="test">
                <a-select placeholder="请选择" v-model="rowData.categoryIds" @change="onChange" mode="multiple">
                    <a-select-option :value="item.id" v-for="(item, index) in scopeList" :key="index">{{item.name}}</a-select-option>
                </a-select>
            </a-form-model-item>
            <a-form-model-item label="开始使用时间" prop="startTime">
                <DatePicker :startTime.sync="rowData.startTime"></DatePicker>
            </a-form-model-item>
            <a-form-model-item label="结束使用时间" prop="endTime">
                <DatePicker :startTime.sync="rowData.endTime"></DatePicker>
            </a-form-model-item>
            <a-form-model-item label="是否启用" prop="flagEnable">
                <a-switch v-model="rowData.flagEnable" checked-children="启用" un-checked-children="禁用" default-checked />
            </a-form-model-item>
            <a-form-model-item label="备注" prop="">
                <a-input v-model="rowData.remark" placeholder="备注"></a-input>
            </a-form-model-item>
        </a-form-model>
    </a-modal>
</template>

<script>
import { editDealerRebate, selectByIdDealerRebate, addDealerRebate } from '../api/DealerRebateTotalApi'
import SelectDealerModal from '../../common/SelectDealerModal.vue'

export default {
    components: {
        SelectDealerModal
    },
    data() {
        return {
            visible: false,
            handle: 'add',
            rowData: {},
            // 表单验证
            formRule: {
                dealerName: [
                    { required: true, message: '请选择经销商', trigger: 'change' }
                ],
                rebateName: [
                    { required: true, message: '请输入折扣名称', trigger: 'blur' }
                ],
                rebateAmount: [
                    { required: true, message: '请输入折扣金额', trigger: 'blur' }
                ],
                categoryIds: [
                    { required: true, message: '请选择适用范围', trigger: 'change' }
                ],
                startTime: [
                    { required: true, message: '请选择开始使用时间', trigger: 'change' }
                ],
                endTime: [
                    { required: true, message: '请选择结束使用时间', trigger: 'change' }
                ],
                flagEnable: [
                    { required: true, message: '请输入是否启用', trigger: 'blur' }
                ],
                remark: [
                    { required: true, message: '请输入备注', trigger: 'blur' }
                ],
            },
            dealerList: [],
            scopeList: [],
            dealerId: []
        }
    },
    methods: {
        /**
         * 获取行数据
         */
        setRowData(row, handle){
            this.handle = handle
            this.visible = true
            this.getList()
            this.rowData = {}
            if(handle == 'add') {
                this.$set(this.rowData, 'flagEnable', true)
            }
            if(handle == 'edit') {
                selectByIdDealerRebate(row.id).then(res => {
                    this.rowData = res.body
                    const ids =  res.body.categoryIdStr.split(',').map(Number)
                    this.rowData.categoryIds = ids
                    console.log(this.rowData.categoryIds)
                })
            }
        },

        getList() {
            // 获取经销商数据
            this.axios.post('/api/firm/mhiac/mhiacFirm/getTree').then(res => {
                this.dealerList = res.body
            })
            // 获取一级分类
            this.axios.get('/api/product/category/parentCategoryList').then(res => {
                this.scopeList = res.body
            })
        },

        onDealerChange() {
            this.$refs.test.onFieldChange()
        },

        onChange(val) {
            this.$refs.text.onFieldChange()
            this.rowData.firmId = val[1]
            this.rowData.dealerId = val[2]
        },

        toSelectDealer(row) {
            this.$set(this.rowData, 'dealerId', row.id)
            this.$set(this.rowData, 'dealerName', row.name)
        },
        /**
         * 表单提交
         */
        toSubmit() {
            this.$refs.form.validate(async valid => {
                if (!valid) {
                    return
                }
                const res = this.handle === 'add' ? await addDealerRebate(this.rowData) : await editDealerRebate(this.rowData)
                if (res.code === 200) {
                    this.$notification.success({ message: res.message })
                    this.$emit('reload')
                    this.visible = false
                    this.rowData = {}
                } else {
                    this.$notification.error({ message: res.message })
                }
            })
        }
    },
    created() {

    },

}
</script>

<style lang="less" scoped>

</style>
