/*
 * @Descripttion:
 * @version:
 * @Author: hutian
 * @Date: 2021-03-12 18:14:13
 * @LastEditors: hutian
 * @LastEditTime: 2021-03-15 18:13:24
 */
/**
* api接口统一管理
*/
import { axios } from '@/utils/request'

export const listDealerRebate = params => axios({
    url: '/api/dealer/dealer/dealerInfo/rebateListForFirm',
    method: 'get',
    params,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})
export const addDealerRebate = params => axios({
    url: '/api/dealer/dealer/dealerRebate/add',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const editDealerRebate = params => axios({
    url: '/api/dealer/dealer/dealerRebate/edit',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const delDealerRebate = params => axios({
    url:'/api/dealer/dealer/dealerRebate/del/' + params,
    method: 'post',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const selectByIdDealerRebate = params => axios({
    url: '/api/dealer/dealer/dealerRebate/selectById/' + params,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const addRebateApplyInfo = params => axios({
  url: '/api/dealer/dealer/dealerRebateApply/add',
  method: 'post',
  data: JSON.stringify(params),
  headers: {
    'Content-Type': 'application/json;charset=UTF-8'
  }
})

export const listRebateApplyList = params => axios({
  url: '/api/dealer/dealer/dealerRebateApply/list',
  method: 'get',
  params,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
  }
})

export const delRebateApply = params => axios({
  url:'/api/dealer/dealer/dealerRebateApply/del/' + params,
  method: 'post',
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
  }
})

export const addDealerRebateFiles = params => axios({
  url: '/api/dealer/dealer/dealerRebate/editFiles',
  method: 'post',
  data: JSON.stringify(params),
  headers: {
    'Content-Type': 'application/json;charset=UTF-8'
  }
})

export const returnStatus = params => axios({
  url:'/api/dealer/dealer/dealerRebateApply/returnStatus/' + params,
  method: 'post',
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
  }
})
